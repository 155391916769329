import React, { useEffect, useRef, useState } from 'react'
import add from "../assets/images/Group1.webp";
import raisedToImg from "../assets/images/Group2.webp";
import equalsImg from "../assets/images/Group3.webp";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../styles/homeBanner.css'
import bannerVideo from '../assets/images/prodmax-banner-vid.mp4'

import gsap from 'gsap'
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);

function HomeBannerUpdated2() {


    const windiwWidth = window.innerWidth;

    const [posOfX, setposOfX] = useState(0);
    const [posOfRaised, setposOfRaised] = useState(0);
    const [posOfEquals, setposOfEquals] = useState(0);
    const [posOfAdd, setposOfAdd] = useState(0);

    // Animetion X to Plus
    const xToPlus = useRef(null);
    const Xpath1 = useRef(null);
    const Xpath2 = useRef(null);
    const Xpath3 = useRef(null);
    const bannerRef = useRef(null);
    const raisedPositionsRef = useRef(null);
    const equalsPositionsRef = useRef(null);
    const addPositionsRef = useRef(null);
    
    const morphToRaised = useRef(null);
    const morphToEquals = useRef(null);

    const imageContainer = useRef(null);
    const technology = useRef(null);
    
    const design = useRef(null);
    const homeBannerPage = useRef(null);
    const ourServicesConatiner = useRef(null);
    
    const designMobile = useRef(null);
    const technologyMobile = useRef(null);

    const morphRaisedRef = useRef(null);
    const morphEqualsRef = useRef(null);

    useEffect(() => {
        if (bannerRef.current) {
          const rect = bannerRef.current.getBoundingClientRect();
          setposOfX(rect); // Set the position of the element
        }

        if (raisedPositionsRef.current) {
            const rect = raisedPositionsRef.current.getBoundingClientRect();
            setposOfRaised(rect); 
          }

          if (equalsPositionsRef.current) {
            const rect = equalsPositionsRef.current.getBoundingClientRect();
            setposOfEquals(rect); 
          }

          if (addPositionsRef.current) {
            const rect = addPositionsRef.current.getBoundingClientRect();
            setposOfAdd(rect); 
          }

          

      }, [bannerRef, raisedPositionsRef, equalsPositionsRef, addPositionsRef]);


    useGSAP(() => {

        if (!posOfX) return; 
        if (!posOfRaised) return; 
        if (!posOfEquals) return; 


        const topPosition = (posOfX.top + posOfRaised.top + posOfEquals.top) / 2

        const timeLine2 = gsap.timeline({
            scrollTrigger : {
                        trigger : (windiwWidth > 990) ? morphRaisedRef.current : technologyMobile.current,
                        scroller : 'body',
                        // start : 'top 70%',
                        start : (windiwWidth > 990) ? "top 70%" : "top 50%",
                        // end : "top 63%",
                        end : (windiwWidth > 990) ? "top 63%" : "top -50%",
                        scrub: 2,

                        onEnter : () => {
                            gsap.to(Xpath1.current, {
                                attr : {
                                    fill : "#fff"
                                },
                            })

                            gsap.to(Xpath2.current, {
                                attr : {
                                    fill : "#fff"
                                },
                            })

                            gsap.to(Xpath3.current, {
                                attr : {
                                    fill : "#fff"
                                },
                            })
                        },

                        onLeaveBack : () => {
                            gsap.to(Xpath1.current, {
                                attr : {
                                    fill : "#000"
                                },
                            })

                            gsap.to(Xpath2.current, {
                                attr : {
                                    fill : "#000"
                                },
                            })

                            gsap.to(Xpath3.current, {
                                attr : {
                                    fill : "#0031FF"
                                },
                            })
                        }

                    }
        })

        timeLine2.to(xToPlus.current , {
                top : posOfRaised.top,
            })


        const timeLine3 = gsap.timeline({
            scrollTrigger : {
                trigger : (windiwWidth > 990) ? morphEqualsRef.current : designMobile.current,
                scroller : 'body',
                // start : 'top 70%',
                start : (windiwWidth > 990) ? "top 70%" : "top 50%",
                // end : "top 63%",
                end : (windiwWidth > 990) ? "top 63%" : "top -50%",
                scrub: 2,

                onEnter : () => {
                    gsap.to(Xpath1.current, {
                        attr : {
                            fill : "#fff"
                        },
                    })

                    gsap.to(Xpath2.current, {
                        attr : {
                            fill : "#fff"
                        },
                    })

                    gsap.to(Xpath3.current, {
                        attr : {
                            fill : "#fff"
                        },
                    })
                },

                onLeaveBack : () => {
                    gsap.to(Xpath1.current, {
                        attr : {
                            fill : "#0031FF"
                        },
                    })

                    gsap.to(Xpath2.current, {
                        attr : {
                            fill : "#000"
                        },
                    })

                    gsap.to(Xpath3.current, {
                        attr : {
                            fill : "#000"
                        },
                    })
                }
            }
        })

        timeLine3.to(xToPlus.current , {
            top : posOfEquals.top,
        })

        const masterTimeLine = gsap.timeline({
            scrollTrigger : {
                trigger: homeBannerPage.current,
                scroller: 'body',
                start: (windiwWidth > 320) ? 'top 0%' : 'top 1%' ,
                // end : (windiwWidth > 990) ? "top -135%" : "top -170%",
                end : (windiwWidth > 990) ? `top -${posOfAdd.top-200}` : `top -${posOfAdd.top-200}`,
                scrub : 2,
                onEnterBack : () => {
                    gsap.to(Xpath1.current, {
                        attr : {
                            fill : "#fff"
                        },
                    })

                    gsap.to(Xpath2.current, {
                        attr : {
                            fill : "#fff"
                        },
                    })

                    gsap.to(Xpath3.current, {
                        attr : {
                            fill : "#fff"
                        },
                    })

                },
                onLeave : () => {
                    gsap.to(Xpath1.current, {
                            attr : {
                                fill : "#000"
                            },
                        })

                        gsap.to(Xpath2.current, {
                            attr : {
                                fill : "#000"
                            },
                        })

                        gsap.to(Xpath3.current, {
                            attr : {
                                fill : "#0031FF"
                            },
                        })

                },
                
            }
        })

        masterTimeLine.from(xToPlus.current , {
                top : posOfX.top,
                left : (windiwWidth > 990) ? posOfX.left : "50%",
                translateX : (windiwWidth > 990) ? null : "-50%",
                scale : (windiwWidth > 1024) ? 1.8 : (windiwWidth > 990) ? 1.5 : (windiwWidth > 425) ? 1.2 : 1,

                // onComplete : () => {
                //     gsap.to(Xpath1.current, {
                //         attr : {
                //             fill : "#000"
                //         },
                //     })

                //     gsap.to(Xpath2.current, {
                //         attr : {
                //             fill : "#000"
                //         },
                //     })

                //     gsap.to(Xpath3.current, {
                //         attr : {
                //             fill : "#0031FF"
                //         },
                //     })

                // },

                
            }
        ).add(timeLine2)

    }, [posOfX, raisedPositionsRef, equalsPositionsRef, posOfRaised, posOfEquals])


    useGSAP(() => {

        const timeLine2 = gsap.timeline({
            scrollTrigger : {
                trigger : (windiwWidth > 990) ? morphRaisedRef.current : morphToRaised.current,
                scroller : 'body',
                start : 'top 68%',
                end : "top 68%",
                scrub: 2,

            }
        })
        timeLine2.to(Xpath1.current , {
            attr : {
                d : "M63.798,18.228 L69,18 L74,18 L83.202,18.228 L84.378,19.404 L87,26 L89.964,32.046 L93.198,39.102 L95,44 L97.02,47.922 L99.666,53.802 L103.488,62.328 L105.84,67.914 L105.546,68.796 L101,69 L96,69 L91,69 L85.848,68.796 L84.966,67.914 L82.614,62.328 L80.85,58.8 L77.616,51.45 L75.852,47.334 L73.794,42.924 L73.5,42.336 L71,47 L68.796,52.626 L66.15,58.506 L62.916,65.856 L61.446,68.502 L61.152,68.796 L56,69 L51,69 L41.454,68.796 L41.16,67.62 L43.806,61.446 L45.864,57.036 L49.098,49.686 L51.156,45.276 L54.096,38.514 L56.154,34.104 L58,29 L60.564,24.108 L62.622,19.698 L63.798,18.228 L63.798,18.228",
                fill : "#0031FF"
            },
            
            

        })

        timeLine2.to(Xpath2.current , {
            attr : {
                
                d : "M127.008,0 L128.772,1.176 L132.006,4.704 L132.888,5.88 L132.888,6.468 L133.476,6.468 L136.122,10.584 L138.768,15.288 L140.826,19.698 L142.884,24.99 L144.942,32.046 L146.412,40.278 L147,44.982 L147,61.152 L145.824,69.384 L144.648,74.676 L142.59,81.732 L140.238,87.612 L137.886,92.022 L136.122,95.256 L132.888,99.96 L130.242,103.194 L128.184,104.958 L126.126,105.84 L124.362,106.134 L117.306,106.134 L106.722,105.84 L105.546,105.252 L104.958,103.782 L105.252,101.724 L107.016,99.372 L108.486,97.902 L110.838,94.668 L113.778,90.258 L115.542,87.318 L118.776,80.556 L121.422,72.618 L122.892,66.444 L123.774,60.27 L124.068,57.036 L124.068,48.216 L122.892,39.396 L121.422,33.222 L119.364,27.342 L117.306,22.344 L114.072,16.17 L110.25,10.29 L106.722,6.174 L105.546,4.704 L105.252,3.822 L105.252,0.882 L106.134,0 L106.134,0 L127.008,0",
                fill : "#000"
            },
            
        })

        timeLine2.to(Xpath3.current , {
            attr : {
                
                d : "M19.992 0 L40.866 0 L41.454 0.294 L41.748 1.176 L41.748 3.234 L40.866 5.292 L37.632 9.114 L33.81 14.406 L31.164 19.11 L28.812 23.814 L26.166 30.87 L24.99 34.986 L23.814 41.16 L22.932 47.628 L22.932 59.388 L24.108 67.326 L25.284 72.324 L26.754 76.734 L28.812 82.026 L31.164 86.436 L32.634 89.376 L35.574 94.08 L38.514 97.902 L38.808 98.784 L39.396 98.784 L41.454 101.136 L41.748 104.076 L41.16 105.252 L39.396 105.84 L19.992 105.84 L18.522 104.958 L15.582 102.018 L12.642 98.196 L9.408 93.198 L7.056 88.494 L5.292 84.378 L3.528 79.38 L2.058 74.382 L0.882 68.208 L0 62.034 L0 43.806 L1.47 34.398 L3.234 27.342 L4.704 22.932 L6.762 17.934 L9.702 12.348 L12.642 7.644 L15.288 4.41 L17.934 1.47 L19.404 0.294 L19.992 0 Z",
                fill : "#000",
            },
        })

        const timeLine3 = gsap.timeline({
            scrollTrigger : {
                trigger : (windiwWidth > 990) ? morphEqualsRef.current : morphToEquals.current,
                scroller : 'body',
                start : 'top 70%',
                end : "top 68%",
                scrub: 2,
            }
        })
        timeLine3.to(Xpath1.current , {
            attr : {
                d : "M7,14 L145,14 L146,14 L146,14 L147,14 L147,15 L148,15 L148,16 L149,16 L149,17 L149,17 L149,18 L149,42 L149,43 L149,43 L149,44 L148,44 L148,45 L147,45 L147,46 L146,46 L146,46 L145,46 L7,46 L6,46 L6,46 L5,46 L5,45 L4,45 L4,44 L3,44 L3,43 L3,43 L3,42 L3,18 L3,18 L3,17 L3,17 L3,16 L4,16 L4,15 L5,15 L5,14 L6,14 L6,14 L7,14",
                fill : "#0031FF"
            },
            
            

        })

        timeLine3.to(Xpath2.current , {
            attr : {
                d : "M7,63 L12,63 L16,63 L21,63 L25,63 L30,63 L34,63 L42,63 L47,63 L51,63 L59,63 L68,63 L76,63 L85,63 L94,63 L103,63 L111,63 L120,63 L128,63 L137,63 L145,63 L146,63 L146,63 L147,63 L147,64 L148,64 L148,65 L149,65 L149,66 L149,66 L149,67 L149,73 L149,79 L149,85 L149,91 L149,92 L149,92 L149,93 L148,93 L148,94 L147,94 L147,95 L146,95 L146,95 L145,95 L137,95 L128,95 L120,95 L111,95 L103,95 L94,95 L85,95 L76,95 L68,95 L59,95 L51,95 L42,95 L34,95 L25,95 L16,95 L7,95 L6,95 L6,95 L5,95 L5,94 L4,94 L4,93 L3,93 L3,92 L3,92 L3,91 L3,85 L3,79 L3,73 L3,67 L3,67 L3,66 L3,66 L3,65 L4,65 L4,64 L5,64 L5,63 L6,63 L6,63 L7,63",
                fill : "#000"
            },
            
        })

        timeLine3.to(Xpath3.current , {
            attr : {
                d : "M7,63 L12,63 L16,63 L21,63 L25,63 L30,63 L34,63 L42,63 L47,63 L51,63 L59,63 L68,63 L76,63 L85,63 L94,63 L103,63 L111,63 L120,63 L128,63 L137,63 L145,63 L146,63 L146,63 L147,63 L147,64 L148,64 L148,65 L149,65 L149,66 L149,66 L149,67 L149,73 L149,79 L149,85 L149,91 L149,92 L149,92 L149,93 L148,93 L148,94 L147,94 L147,95 L146,95 L146,95 L145,95 L137,95 L128,95 L120,95 L111,95 L103,95 L94,95 L85,95 L76,95 L68,95 L59,95 L51,95 L42,95 L34,95 L25,95 L16,95 L7,95 L6,95 L6,95 L5,95 L5,94 L4,94 L4,93 L3,93 L3,92 L3,92 L3,91 L3,85 L3,79 L3,73 L3,67 L3,67 L3,66 L3,66 L3,65 L4,65 L4,64 L5,64 L5,63 L6,63 L6,63 L7,63",
                fill : "#000"
            },
        })

        const masterTimeLine = gsap.timeline({
            scrollTrigger : {
                trigger : imageContainer.current,
                scroller : 'body',
                start : 'top 70%',
                end : "top 68%",
                scrub: 2,
            }
        }).add(timeLine2)

        masterTimeLine.from(Xpath1.current , {
            attr : {
                d : "M158.624 0.293091C149.425 0.436219 141.209 0.644296 140.368 0.75535C138.548 0.995902 136.852 1.79573 135.792 2.91329C134.26 4.5296 91.738 60.2551 91.2903 61.2335C90.2458 63.5168 90.2089 65.8479 91.1868 67.7834C91.9739 69.3409 111.328 94.1937 112.259 94.8424C113.455 95.6753 114.676 96.0493 116.212 96.0531C118.159 96.058 119.616 95.46 120.915 94.1229C122.643 92.3442 183.805 12.8008 184.367 11.6008C185.054 10.136 185.202 7.76622 184.712 6.07233C183.632 2.33156 180.286 -0.107226 176.386 0.00362773C175.816 0.0198649 167.823 0.150164 158.624 0.293091Z",
                // fill : "#fff"
            },
            
        })

        masterTimeLine.from(Xpath2.current , {
            attr : {
                d : "M26.4895 33.8704C5.00706 34.1218 6.76617 34.0456 5.18672 34.7932C1.72224 36.4325 -0.0067974 39.8277 2.00815e-05 44.9773C0.00342882 47.5752 0.294374 49.0839 1.12089 50.7908C1.38798 51.3421 12.258 65.3468 25.2766 81.9123C38.2954 98.4779 48.9471 112.076 48.9471 112.131C48.9471 112.186 38.361 126.416 25.4224 143.753C12.484 161.091 1.65386 175.637 1.3555 176.078C0.0274925 178.041 -0.370532 181.481 0.430121 184.073C1.29734 186.88 3.72016 189.387 6.3501 190.199C7.61194 190.589 8.36648 190.61 25.8485 190.737C42.2457 190.856 44.1579 190.837 45.2876 190.544C46.9747 190.106 48.0109 189.55 49.1849 188.452C49.7255 187.947 56.6302 179.154 64.5287 168.913L78.8895 150.293L79.3294 150.757C79.5714 151.012 86.3617 159.618 94.4187 169.882C102.476 180.145 109.359 188.788 109.715 189.087C110.652 189.875 112.119 190.571 113.559 190.91C115.474 191.362 149.366 191.357 151.102 190.905C152.766 190.472 154.116 189.689 155.464 188.375C158.134 185.772 158.887 181.838 157.355 178.492C156.851 177.392 49.6762 37.9686 47.9929 36.224C46.334 34.5047 43.7668 33.6086 40.7261 33.688C40.1195 33.7039 33.7131 33.786 26.4895 33.8704Z",
                // fill : "#fff"
            },
           
        })

        masterTimeLine.from(Xpath3.current , {
            opacity : 0,

        })

    }, )



 



  return (
        <>
            <div className="prodmax-banner">

                <div className="container">
                    <section className='home-banner' ref={homeBannerPage}>
                        <video autoPlay muted loop className="background-video">
                            <source src={bannerVideo} type="video/mp4" />
                        </video>
                        <div className="banner-heading" >

                            <h1>Drive Customer Growth  </h1>
                            <h1>with Integrated Digital Strategy</h1>
                        </div>

                        <div className="banner-image-container" ref={bannerRef} >
                            
                        </div>
                    </section>
                </div>
            </div>

            <section className='our-services' ref={ourServicesConatiner}>
                <div className="container">

                    <div className="heading">
                        <h1 style={{position: 'relative', zIndex : 100}}>Marketing Services</h1>
                        <p  className='smaller' style={{position: 'relative', zIndex : 100}}>Welcome to Prodmax: Your Partner in Digital Growth</p>
                        <br />
                        <p className='smaller' style={{position: 'relative', zIndex : 100}}>At Prodmax, we are passionate about driving success through innovative digital marketing and technology solutions. With our deep expertise in customer acquisition, cutting-edge technology, and exceptional design, we empower brands to achieve unparalleled growth in a competitive digital landscape.</p>
                        <br />
                        <p className='smaller' style={{position: 'relative', zIndex : 100}}>Our comprehensive services are tailored to meet the unique needs of your business, ensuring a seamless and engaging experience for your customers. From crafting compelling marketing campaigns and creating user-centric designs to developing robust tech solutions, Prodmax is dedicated to helping your business thrive.</p>
                        <br />
                        <p className='smaller' style={{position: 'relative', zIndex : 100}}>Join us on a journey to elevate your brand, enhance customer engagement, and drive sustained business success. With Prodmax as your partner, unlock new opportunities and transform your digital presence. Let’s achieve greatness together.</p>
                    </div>


                    <div className="customer-aq">
                        <h2  style={{position: 'relative', zIndex : 100}}>Customer Acquisition </h2>


                        <div className="animation-container" >
                            <div className="image-container"  ref={imageContainer} >
                                <img src={add} alt="add" style={{opacity : 0}} ref={addPositionsRef}  />

                                <svg 
                                    viewBox="0 0 184 189" 
                                    fill="none" 
                                    ref={xToPlus} 
                                    className='svg'
                                >
                                    <g>
                                        <path ref={Xpath1} d="M4,67 L148,67.4074 L149,67 L149,68 L150,68 L150,68 L151,69 L151,69 L152,70 L152,70 L152,71 L152,71 L152,96.1481 L152,97 L152,97 L152,98 L151,99 L151,99 L150,99 L150,100 L149,100 L149,100 L148,100 L4,100.148 L3,100 L3,100 L2,100 L2,99 L1,99 L1,99 L0,98 L0,97 L0,97 L0,96 L0,71.4074 L0,71.4074 L0,71 L0,70 L0,70 L1,69 L1,69 L2,68 L2,68 L3,68 L3,67 L4,67" fill="#fff"/>

                                        <path ref={Xpath2} d="M61,78 L65,78 L68,78 L74,78 L78,78 L81,78 L87,78 L88,78 L88,78 L89,78 L89,79 L90,79 L90,80 L91,80 L91,81 L91,81 L91,82 L91,87 L91,91 L91,96 L91,100 L91,105 L91,109 L91,113 L91,117 L91,122 L91,126 L91,131 L91,135 L91,140 L91,144 L91,148 L91,152 L91,153 L91,153 L91,154 L90,154 L90,155 L89,155 L89,156 L88,156 L88,156 L87,156 L81,156 L78,156 L74,156 L68,156 L65,156 L61,156 L61,156 L60,156 L60,156 L59,156 L59,155 L58,155 L58,154 L57,154 L57,153 L57,153 L57,152 L57,148 L57,144 L57,140 L57,135 L57,131 L57,126 L57,122 L57,117 L57,113 L57,109 L57,105 L57,100 L57,96 L57,91 L57,87 L57,82 L57,81 L57,81 L57,80 L58,80 L58,79 L59,79 L59,78 L60,78 L60,78 L61,78" fill="#fff"/>

                                        <path ref={Xpath3} d="M61,0 L65,0 L68,0 L71,0 L74,0 L78,0 L81,0 L84,0 L87,0 L88,0 L88,0 L89,0 L89,1 L90,1 L90,2 L91,2 L91,3 L91,3 L91,4 L91,8 L91,11 L91,14 L91,17 L91,21 L91,24 L91,27 L91,30 L91,34 L91,37 L91,40 L91,43 L91,46 L91,49 L91,52 L91,55.7037 L91,56 L91,57 L91,58 L90,58 L90,59 L89,59 L89,59 L88,59 L88,60 L87,60 L84,60 L81,60 L78,60 L74,60 L71,60 L68,60 L65,60 L61,59.7037 L61,59.7037 L60,60 L60,59 L59,59 L59,59 L58,59 L58,58 L57,58 L57,57 L57,56 L57,56 L57,50 L57,47 L57,43 L57,37 L57,34 L57,30 L57,24 L57,21 L57,17 L57,11 L57,8 L57,4 L57,3 L57,3 L57,2 L58,2 L58,1 L59,1 L59,0 L60,0 L60,0 L61,0" fill="#fff"/>
                                    </g>
                                </svg>


                            </div>

                            <div className="info-container">
                                <h3 style={{position: 'relative', zIndex : 100}} ref={technologyMobile} ><span>Marketing <span style={{fontWeight: 700}}>+</span>  Sales</span></h3>
                                <p className='smaller' style={{position: 'relative', zIndex : 100}}>At Prodmax, our seasoned customer acquisition services have successfully driven hundreds of thousands of acquisitions. We seamlessly integrate marketing and sales to achieve measurable growth for your business. We begin by thoroughly understanding your brand and target audience, auditing their customer purchase or intent journey, and comparing it with close competitors. This allows us to set measurable goals and employ data-driven consumer insights and marketing strategies to attract, engage, and convert prospects into loyal customers.</p>
                                <br />
                                <p className='smaller'>Our approach combines these consumer insights with targeted marketing campaigns, compelling design and content, and multi-channel strategies to maximise reach and impact. Our sales experts collaborate closely with your team to refine the sales funnel, ensuring every lead is nurtured and guided towards conversion. </p>
                                <br />

                                <p className='smaller'>With a focus on personalised experiences and strategic outreach, we help you build strong customer relationships and achieve sustained business success. Partner with Prodmax to unlock the full potential of your customer acquisition efforts and propel your business forward.</p>
                            </div>

                        </div>

                    </div>

                    

                    <div className="customer-aq">
                        <h2 ref={morphToRaised}  style={{position: 'relative', zIndex : 100}} >Technology</h2>


                        <div className="animation-container">
                            <div className="image-container">
                                <img src={raisedToImg} alt="raised-to" style={{opacity : 0}} ref={raisedPositionsRef}/>
                                
                            </div>

                            <div className="info-container" ref={technology}>
                                {/* <h3><span>(Development)<sup style={{fontWeight: 700}}>^</sup></span></h3> */}
                                <h3 ref={designMobile}><span>Automation to Scale</span></h3>

                                <p className='smaller' style={{position: 'relative', zIndex : 100}}>At Prodmax, we believe that powerful technology is the cornerstone of effective customer acquisition. Our offerings encompass a wide range of solutions, including Website Development, E-commerce Stores, Landing Pages, Payment Gateways, Mobile Apps, CRM Systems, Learning Management Systems, Automation, and Marketing Tools and Platforms to track and measure the consumer journey.</p>
                                <br />

                                <p className='smaller' ref={morphRaisedRef}>We specialise in creating and offering plug-and-play, customer-centric tech solutions that ensure a seamless consumer experience—an essential component of today’s overall brand experience. These solutions are the best-kept secrets for businesses aiming to succeed in the digital world.</p>
                                <br />

                                <p className='smaller'>Many businesses fail to scale due to inadequate technology usage and implementation, resulting in higher manpower costs and stunted growth. By embedding technology throughout the consumer journey, we ensure a consistent experience and equip your brand to scale effectively in a competitive digital landscape.</p>
                            </div>

                        </div>

                    </div>

                    <div className="customer-aq">
                        <h2  ref={morphToEquals} style={{position: 'relative', zIndex : 100}}>Design Studio</h2>



                        <div className="animation-container">
                            <div className="image-container">
                                <img src={equalsImg} alt="equals" style={{opacity : 0}} ref={equalsPositionsRef} />

                            </div>

                            <div className="info-container" ref={design}>
                                {/* <h3><span>Web Design + Logo Design <span style={{fontWeight: 700}}>=</span> Brand Kit   Sales</span></h3> */}
                                <h3><span>Interaction to Experience</span></h3>
                                <p className='smaller' style={{position: 'relative', zIndex : 100}}>At Prodmax, we firmly believe that “Good Design is Good Business.” This philosophy is ingrained in every aspect of our customer acquisition solutions. We deeply immerse ourselves in understanding the brand essence and consumer pulse, translating these insights into immersive and engaging designs for both new and existing brands and companies.</p>
                                <br />

                                <p className='smaller' ref={morphEqualsRef}>We specialise in creating comprehensive brand identity elements, ensuring that every visual component aligns with your brand’s vision and goals. Our expertise includes Logo, Website, Mobile App, Marketing Collaterals, Brand Explainer Video, Video Ads.</p>
                                <br />

                                <p className='smaller'>By integrating these design elements into your overall brand strategy, we create a cohesive and visually compelling experience that resonates with your target audience. Our goal is to enhance customer engagement, build brand loyalty, and drive business growth through exceptional design.</p>
                            </div>

                        </div>

                    </div>
                    

                </div>

            </section>

        </>
  )
}

export default HomeBannerUpdated2